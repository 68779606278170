<template>
  <!-- 授课老师、助教老师选择组件 -->
  <div class="teacher-select">
    <!-- 选择角色 -->
    <el-select v-model="xRole" placeholder="请选择角色" filterable style="margin-right:10px; width:110px"
      @change="changeRole" :disabled="isDisable">
      <el-option :label="item.name" :value="item.val" v-for="(item, index) in roleOption" :key="index"></el-option>
    </el-select>
    <!-- 根据角色选择老师 -->
    <el-select v-model="xSelected" placeholder="请选择授课老师" filterable @change="changeSelected" :disabled="isDisable">
      <el-option :label="item.name" :value="item.id" v-for="(item, index) in list" :key="index"></el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    props: {
      // 请求类型 （授课老师，助教老师）
      requestType: {
        required: true,
        type: String,
      },
      // 请求所用课程id
      courseId: {
        type: Number
      },
      // 回显时的身份
      role: {
        type: String,
      },
      // 回显时选中教师id
      selected: {
        type: Number
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isDisable: false,
        list: "",
        xRole: '123',
        xSelected: undefined,
        roleOption: [{
          name: '专家',
          val: '123',
          id:123,
        }, {
          name: '助教',
          val: '101',
          id:101,
        }, {
          name: '参训教师',
          val: '100',
          id:100,
        }, {
          name: '管理者',
          val: '124',
          id:124,
        }]
      }
    },
    computed: {},
    watch: {
      isDisabled(newVal, oldVal) {
        if (newVal) {
          this.isDisable = true
        }
      },
      selected(newVal, oldVal) {
        if (newVal) this.xSelected = newVal;
      },
      role(newVal, oldVal) {
        if (newVal) {
          this.xRole = newVal;
          this.getList();
        }
      },
      courseId(newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          if (newVal === oldVal) return;
          this.getList();
        }
      }
    },
    methods: {
      // 改变选中老师
      changeSelected(val) {
        this.xSelected = val;
        this.$emit('teacherSelect', {
          role: this.xRole,
          teacherId: this.xSelected,
          type: this.requestType
        });
      },
      // 改变身份
      changeRole(val) {
        this.xRole = val;
        this.xSelected = undefined;
        this.getList();
      },
      //获取老师列表
      async getList() {
        this.list = [];
        let params = {
          projectId: this.courseId,
          type: this.xRole
        };
        console.log(params);
        if (!params.projectId) return;
        let resData = await this.$Api.Course.getSalonTeacher(params);
        // console.log(resData);
        this.list = resData.data;
      },
    },
    mounted() {
      this.isDisable = this.isDisabled;
      this.getList();
    },
    activated() {
      this.isDisable = this.isDisabled;
      this.getList();
    },
    deactivated() {
      this.xRole = 'expert';
      this.list = [];
      this.xSelected = undefined;
      this.isDisable = false
    },
  }
</script>

<style>

</style>